/**
 * @description: 获取指定参数
 * @param {string} name 参数名(不区分大小写)
 * @return {string}
 */
export function getQueryString(name: string): string {
  // 获取url上的参数（使用decodeURIComponent对url参数进行解码）
  let search = decodeURIComponent(window.location.search).replace('?', '')
  const tempArr = search !== '' ? search.split('&') : []

  // 将参数名转小写，参数值保留原大小写
  tempArr.forEach((item) => {
    if (item) {
      const itemArr = item.split('=')
      search = search.replace(itemArr[0], itemArr[0].toLowerCase())
    }
  })

  // 正则匹配指定的参数
  const reg = new RegExp(`(^|&)${name.toLowerCase()}=([^&]*)(&|$)`)
  const result = search.match(reg)

  return result != null ? result[2] : ''
}

/**
 * @description: 获取 cookie
 * @param {string} name
 * @param {string} cookieName
 * @return {string}
 */
export function getCookie(name: string, cookieName: string): string {
  const cie = new RegExp(
    `(?:^|; )${encodeURIComponent(cookieName || name || '')}=([^;]*)`,
  ).exec(document.cookie)
  if (!cie)
    return ''
  if (!cookieName) {
    return cie ? decodeURIComponent(cie[1]) : ''
  }
  else {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
    const r = cie[1].match(reg)
    if (r != null)
      return unescape(decodeURIComponent(r[2]))
    return ''
  }
}

/**
 * @description: 激活失活
 * @param {*} func 接收一个函数，激活时触发 true 激活；false 失活；
 * @return {*}
 */
// let isFirst = 0;
export function onPageWake(func: (visable: boolean) => void): void {
  document.addEventListener('visibilitychange', () => {
    // 用户息屏、或者切到后台运行 （离开页面）
    if (document.visibilityState === 'hidden') {
      func?.(false)
    }
    // 用户打开或回到页面
    if (document.visibilityState === 'visible') {
      func?.(true)
    }
  })
  // onPageStateChange((val) => {
  //   if (val == true && isFirst > 0) {
  //     func?.();
  //   } else {
  //     isFirst++;
  //   }
  // });
}

// Debounce function to limit how often a function can run
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>
  return function (...args: Parameters<T>) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

/**
 * 将文本内容复制到剪切板
 * @param text
 */
export function copyToClipboard(text: string) {
  if (import.meta.server) {
    console.error('copyToClipboard is not supported in server side')
    return
  }
  const textarea = document.createElement('textarea')
  textarea.style.position = 'fixed'
  textarea.style.opacity = '0'
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

// eslint-disable-next-line antfu/top-level-function
export const NOOP = (): void => {}

// 获取评分文本
export function getScoreText(score: number) {
  const { t } = useI18n()
  if (score >= 4.5)
    // return 'Fabulous'
    return t('key.search.result.score.desc.fabulous')
  if (score >= 4.0)
    // return 'Very good'
    return t('key.search.result.score.desc.very.good')
  if (score >= 3.5)
    // return 'Good'
    return t('key.search.result.score.desc.good')
  if (score >= 3.0)
    // return 'Pleasant'
    return t('key.search.result.score.desc.pleasant')
  return ''
}

export function getCommonQuery() {
  const route = useRoute()
  const commonQueryKeys = [
    'currency',
    'sid',
    'aid',
    'ouid',
    'campaign_id',
    'campaign_name',
    'memberId',
    'member_id',
  ]
  return commonQueryKeys.reduce((acc, key) => {
    if (route.query[key]) {
      acc[key] = route.query[key] as string
    }
    return acc
  }, {} as Record<string, string>)
}
